<template>
  <div class="yellow_bg">
    <div v-if="$lang === 'hr'" class="container pt-5 pb-5"></div>

    <div v-else class="container pt-5 pb-5">
      <div>
        <h3 class="text-center">Supplemental Privacy Notice for Residents of California</h3>
        <br>
        <p>
          This Privacy Notice for California Residents supplements the information contained in the Terralogix Group
          <router-link :to="{name: 'privacy_policy'}">Privacy Policy</router-link>
          and applies solely to all visitors, users, and others who reside in the State of California
          (”consumers” or “you”). We adopt this notice to comply with the California Consumer Privacy Act of 2018 (CCPA)
          and any terms defined in the CCPA have the same meaning when used in this Notice.
        </p>
        <p>
          Where noted in this Notice, the CCPA temporarily exempts personal information reflecting a written or verbal
          business-to-business communication (“B2B personal information”) from some of its requirements. <br>
          Information We Collect
        </p>
        <p>
          Terralogix Group Ltd. and its affiliated companies (“Terralogix”) collect information that identifies, relates
          to, describes, references, is reasonably capable of being associated with, or could reasonably be linked,
          directly or indirectly, with a particular consumer, household, or device (“personal information”). Personal
          information does not include:
        </p>
        <p>
          Publicly available information from government records.
        </p>
        <p>
          Deidentified or aggregated consumer information.
        </p>
        <p>
          Information excluded from the CCPA’s scope, such as:
        </p>
        <ul>
          <li>
            health or medical information covered by the Health Insurance Portability and Accountability Act of 1996
            (HIPAA) and the California Confidentiality of Medical Information Act (CMIA) or clinical trial data;
          </li>
          <li>
            personal information covered by certain sector-specific privacy laws, including the Fair Credit Reporting
            Act (FCRA), the Gramm-Leach-Bliley Act (GLBA) or California Financial Information Privacy Act (FIPA), and
            the Driver’s Privacy Protection Act of 1994.
          </li>
        </ul>
        <p>
          In particular, Terralogix collected the following categories of personal information from its consumers within
          the last twelve (12) months:
        </p>
        <table>
          <tr>
            <th>Category</th>
            <th>Example</th>
            <th>Collected</th>
          </tr>
          <tr>
            <td>A. Identifiers.</td>
            <td>
              A real name, alias, postal address, unique personal identifier, online identifier, Internet Protocol
              address, email address, account name, Social Security number, driver’s license number, passport number, or
              other similar identifiers
            </td>
            <td>Yes</td>
          </tr>
          <tr>
            <td>
              B. Personal information categories listed in the California Customer Records statute (Cal. Civ. Code §
              1798.80(e)).
            </td>
            <td>
              A name, signature, Social Security number, physical characteristics or description, address, telephone
              number, passport number, driver’s license or state identification card number, insurance policy number,
              education, employment, employment history, bank account number, credit card number, debit card number, or
              any other financial information, medical information, or health insurance information. Some personal
              information included in this category may overlap with other categories.
            </td>
            <td>Yes</td>
          </tr>
          <tr>
            <td>C. Protected classification characteristics under California or federal law.</td>
            <td>
              Age (40 years or older), race, color, ancestry, national origin, citizenship, religion or creed, marital
              status, medical condition, physical or mental disability, sex (including gender, gender identity, gender
              expression, pregnancy or childbirth and related medical conditions), sexual orientation, veteran or
              military status, genetic information (including familial genetic information).
            </td>
            <td>Yes</td>
          </tr>
          <tr>
            <td>D. Commercial information.</td>
            <td>
              Records of personal property, products or services purchased, obtained, or considered, or other purchasing
              or consuming histories or tendencies.
            </td>
            <td>No</td>
          </tr>
          <tr>
            <td>E. Biometric information.</td>
            <td>
              Genetic, physiological, behavioral, and biological characteristics, or activity patterns used to extract a
              template or other identifier or identifying information, such as, fingerprints, faceprints, and
              voiceprints, iris or retina scans, keystroke, gait, or other physical patterns, and sleep, health, or
              exercise data.
            </td>
            <td>No</td>
          </tr>
          <tr>
            <td>F. Internet or other similar network activity.</td>
            <td>
              Browsing history, search history, information on a consumer’s interaction with a website, application, or
              advertisement.
            </td>
            <td>Yes</td>
          </tr>
          <tr>
            <td>G. Geolocation data.</td>
            <td>Physical location or movements.</td>
            <td>No</td>
          </tr>
          <tr>
            <td>H. Sensory data.</td>
            <td>
              Audio, electronic, visual, thermal, olfactory, or similar information. <br>
              Yes
            </td>
            <td>No</td>
          </tr>
          <tr>
            <td>I. Professional or employment-related information.</td>
            <td>Current or past job history or performance evaluations.</td>
            <td>No</td>
          </tr>
          <tr>
            <td>
              J. Non-public education information (per the Family Educational Rights and Privacy Act (20 U.S.C. Section
              1232g, 34 C.F.R. Part 99)).
            </td>
            <td>
              Education records directly related to a student maintained by an educational institution or party acting
              on its behalf, such as grades, transcripts, class lists, student schedules, student identification codes,
              student financial information, or student disciplinary records.
            </td>
            <td>No</td>
          </tr>
          <tr>
            <td>K. Inferences drawn from other personal information,</td>
            <td>
              Profile reflecting a person’s preferences, characteristics, psychological trends, predispositions,
              behavior, attitudes, intelligence, abilities, and aptitudes. <br>
              No
            </td>
            <td>No</td>
          </tr>
        </table>
        <br>
        <p>
          Terralogix obtains the categories of personal information listed above from the following categories of
          sources:
        </p>
        <ul>
          <li>
            Directly from you. For example, from forms you complete or products and services you purchase.
          </li>
          <li>
            Indirectly from you. For example, from observing your actions on our Website.
          </li>
          <li>
            From third parties. For example, from our third-party service providers to verify your identity or to
            facilitate our provision of services to you.
          </li>
        </ul>
        <br>
        <h4>Use of Personal Information</h4>
        <p>
          We may use or disclose the personal information we collect for one or more of the following purposes:
        </p>
        <ul>
          <li>
            To fulfill or meet the reason you provided the information. For example, if you share your name and contact
            information to ask a question about our products or services, we will use that personal information to
            respond to your inquiry. If you provide your personal information to utilize our services, we will use that
            information to verify your identity, and facilitate the provision of our services. We may also save your
            information to facilitate new requests and to comply with relevant laws.
          </li>
          <li>
            To provide, support, personalize, and develop our Website, products, and services.
          </li>
          <li>
            To create, maintain, customize, and secure your account with us.
          </li>
          <li>
            To process your requests, purchases, transactions, and payments and prevent transactional fraud.
          </li>
          <li>
            To provide you with support and to respond to your inquiries, including to investigate and address your
            concerns and monitor and improve our responses.
          </li>
          <li>
            To personalize your Website experience and to deliver content and product and service offerings relevant to
            your interests, including targeted offers and ads through our Website, third-party sites, and via email or
            text message (with your consent, where required by law).
          </li>
          <li>
            To help maintain the safety, security, and integrity of our Website, products and services, databases and
            other technology assets, and business.
          </li>
          <li>
            For testing, research, analysis, and product development, including to develop and improve our Website,
            products, and services.
          </li>
          <li>
            To respond to law enforcement requests and as required by applicable law, court order, or governmental
            regulations.
          </li>
          <li>
            As described to you when collecting your personal information or as otherwise set forth in the CCPA.
          </li>
          <li>
            To evaluate or conduct a merger, divestiture, restructuring, reorganization, dissolution, or other sale or
            transfer of some or all of our assets, whether as a going concern or as part of bankruptcy, liquidation, or
            similar proceeding, in which personal information held by us about our Website users is among the assets
            transferred.
          </li>
        </ul>
        <p>
          We will not collect additional categories of personal information or use the personal information we collected
          for materially different, unrelated, or incompatible purposes without providing you notice.
        </p>
        <br>
        <h4>Sharing Personal Information</h4>
        <p>
          We do not sell your personal information. We may disclose your personal information to a third party for a
          business purpose. When we disclose personal information for a business purpose, we enter a contract that
          describes the purpose and requires the recipient to both keep that personal information confidential and not
          use it for any purpose except performing the contract.
        </p>
        <p>
          We share your personal information with the following categories of third parties:
        </p>
        <ul>
          <li>Service providers.</li>
          <li>Regulatory or government agencies required by law.</li>
        </ul>
        <br>
        <h4>
          Disclosures of Personal Information for a Business Purpose
        </h4>
        <p>
          In the preceding twelve (12) months, Terralogix has disclosed the following categories of personal information
          for a business purpose:
        </p>
        <ul>
          <li>Category A: Identifiers</li>
          <li>Category B: California Customer Records personal information categories.</li>
          <li>Category C: Protected classification characteristics under California or federal law.</li>
          <li>Category F: Internet or other similar network activity.</li>
        </ul>
        <p>
          We disclose your personal information for a business purpose to the following categories of third parties:
        </p>
        <ul>
          <li>Service providers.</li>
        </ul>
        <br>
        <h4>Sales of Personal Information</h4>
        <p>In the preceding twelve (12) months, Terralogix has not sold personal information.</p>
        <br>
        <h4>Your Rights and Choices</h4>
        <p>
          The CCPA provides consumers (California residents) with specific rights regarding their personal information.
          This section describes your CCPA rights and explains how to exercise those rights.
        </p>
        <br>
        <h4>Access to Specific Information and Data Portability Rights</h4>
        <p>
          You have the right to request that we disclose certain information to you about our collection and use of your
          personal information over the past 12 months. Once we receive and confirm your verifiable consumer request
          (see Exercising Access, Data Portability, and Deletion Rights section below), we will disclose to you:
        </p>
        <ul>
          <li>The categories of personal information we collected about you.</li>
          <li>The categories of sources for the personal information we collected about you.</li>
          <li>Our business or commercial purpose for collecting or selling that personal information.</li>
          <li>The categories of third parties with whom we share that personal information.</li>
          <li>The specific pieces of personal information we collected about you (also called a data portability
            request).
          </li>
          <li>If we sold or disclosed your personal information for a business purpose.</li>
        </ul>
        <p>
          We do not provide these access and data portability rights for B2B personal information.
        </p>
        <br>
        <h4>Deletion Request Rights</h4>
        <p>
          You have the right to request that we delete any of your personal information that we collected from you and
          retained, subject to certain exceptions. Once we receive and confirm your verifiable consumer request (see
          Exercising Access, Data Portability, and Deletion Rights section below), we will delete (and direct our
          service providers to delete) your personal information from our records, unless an exception applies.
        </p>
        <p>
          We may deny your deletion request if retaining the information is necessary for us or our service provider(s)
          to:
        </p>
        <ul>
          <li>
            Complete the transaction for which we collected the personal information, provide a good or service that you
            requested, take actions reasonably anticipated within the context of our ongoing business relationship with
            you, fulfill the terms of a written warranty or product recall conducted in accordance with federal law, or
            otherwise perform our contract with you.
          </li>
          <li>
            Detect security incidents, protect against malicious, deceptive, fraudulent, or illegal activity, or
            prosecute those responsible for such activities.
          </li>
          <li>Debug products to identify and repair errors that impair existing intended functionality.</li>
          <li>
            Exercise free speech, ensure the right of another consumer to exercise their free speech rights, or exercise
            another right provided for by law.
          </li>
          <li>
            Comply with the California Electronic Communications Privacy Act (Cal. Penal Code § 1546 et. seq.).
          </li>
          <li>
            Engage in public or peer-reviewed scientific, historical, or statistical research in the public interest
            that adheres to all other applicable ethics and privacy laws, when the information’s deletion may likely
            render impossible or seriously impair the research’s achievement, if you previously provided informed
            consent.
          </li>
          <li>
            Enable solely internal uses that are reasonably aligned with consumer expectations based on your
            relationship with us.
          </li>
          <li>Comply with a legal obligation.</li>
          <li>Make other internal and lawful uses of that information that are compatible with the context in which you
            provided it.
          </li>
        </ul>
        <p>We do not provide these deletion rights for B2B personal information.</p>
        <br>
        <h4>Exercising Access, Data Portability, and Deletion Rights</h4>
        <p>
          To exercise the access, data portability, and deletion rights described above, please submit a verifiable
          consumer request to privacy@terralogix.ai.
        </p>
        <p>
          Only you, or someone legally authorized to act on your behalf, may make a verifiable consumer request related
          to your personal information.
        </p>
        <p>
          You may only make a verifiable consumer request for access or data portability twice within a 12-month period.
          The verifiable consumer request must:
        </p>
        <ul>
          <li>
            Provide sufficient information that allows us to reasonably verify you are the person about whom we
            collected personal information or an authorized representative.
          </li>
          <li>
            Describe your request with sufficient detail that allows us to properly understand, evaluate, and respond to
            it.
          </li>
        </ul>
        <p>
          We cannot respond to your request or provide you with personal information if we cannot verify your identity
          or authority to make the request and confirm the personal information relates to you.
        </p>
        <p>
          Making a verifiable consumer request does not require you to create an account with us.
        </p>
        <p>
          We will only use personal information provided in a verifiable consumer request to verify the requestor’s
          identity or authority to make the request.
        </p>
        <br>
        <h4>Response Timing and Format</h4>
        <p>
          We endeavor to respond to a verifiable consumer request within forty-five (45) days of its receipt. If we
          require more time (up to 90 days), we will inform you of the reason and extension period in writing.
        </p>
        <p>
          We will deliver our written response to the email used when submitting your request through the Data Subject
          Request Portal.
        </p>
        <p>
          Any disclosures we provide will only cover the 12-month period preceding the verifiable consumer request’s
          receipt. The response we provide will also explain the reasons we cannot comply with a request, if applicable.
          For data portability requests, we will select a format to provide your personal information that is readily
          useable and should allow you to transmit the information from one entity to another entity without hindrance.
        </p>
        <p>
          We do not charge a fee to process or respond to your verifiable consumer request unless it is excessive,
          repetitive, or manifestly unfounded. If we determine that the request warrants a fee, we will tell you why we
          made that decision and provide you with a cost estimate before completing your request.
        </p>
        <br>
        <h4>Personal Information Sales Opt-Out and Opt-In Rights</h4>
        <p>Terralogix does not sell personal information, so there is no need to opt out.</p>
        <br>
        <h4>Non-Discrimination</h4>
        <p>We will not discriminate against you for exercising any of your CCPA rights. Unless permitted by the CCPA, we
          will not:</p>
        <ul>
          <li>Deny you goods or services.</li>
          <li>
            Charge you different prices or rates for goods or services, including through granting discounts or other
            benefits, or imposing penalties.
          </li>
          <li>Provide you a different level or quality of goods or services.</li>
          <li>
            Suggest that you may receive a different price or rate for goods or services or a different level or quality
            of goods or services.
          </li>
        </ul>
        <p>
          However, we may offer you certain financial incentives permitted by the CCPA that can result in different
          prices, rates, or quality levels. Any CCPA-permitted financial incentive we offer will reasonably relate to
          your personal information’s value and contain written terms that describe the program’s material aspects.
        </p>
        <br>
        <h4>Changes to Our Privacy Notice</h4>
        <p>
          We reserve the right to amend this privacy notice at our discretion and at any time. When we make changes to
          this privacy notice, we will post the updated notice on the Website and update the notice’s effective date.
          Your continued use of our Website following the posting of changes constitutes your acceptance of such
          changes.
        </p>
        <br>
        <h4>Contact Information</h4>
        <p>
          If you have any questions or comments about this notice, the ways in which Terralogix collects and uses your
          information described here and in the
          <router-link :to="{name: 'privacy_policy'}">Privacy and Data Policy</router-link>,
          your choices and rights regarding such use, or
          wish to exercise your rights under California law, please do not hesitate to contact us at
          privacy@terraadriatica.hr.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CaliforniaPolicy"
};
</script>

<style scoped>
table, th, td {
  border: 1px solid currentColor;
}
th, td {
  padding: 5px;
}
</style>
